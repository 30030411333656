@font-face {
  font-family: myFirstFont;
  src: url(./fontfamily/bukra_light.otf);
}

body {
  background-color: #FDF8F0;
  font-weight:100;
}
 
.navbar{
  background: #F5E6DD;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
}
.nav-container{
  width: 75%;
  display: flex;
  justify-content: center;
  /* margin-right: 20px; */
}
.btn{
  padding: 5px 10px;
  border: none;
  color: #FFF;
  margin: 0px 10px;  
}
.btn-complete-tree{
  background: #C67D49;
}
.btn-male-tree{
  background: #737D67;
}
.btn-female-tree{
  color: #C67D49;
  background: #E3BEA6;
}
.btn-print-tree{
  color: #737D67;
  background: transparent;
  border: 1px solid #737D67;
  display: flex;
}
.header{
  margin-top: 30px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center ;
  text-align: right;
}
.btn-conainer{
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}
.linage {
  fill: none;
  stroke: #C67D49;
}
.marriage,.rd3t-link {
  fill: none;
  stroke: #C67D49 !important;
}
.hovericon{
  display: none;
}
.Male:hover :nth-child(3) { 
  display: none; 
}
.Male:hover :nth-child(2) { 
  display: inline-block; 
}
.Female:hover :nth-child(2) { 
  display: inline-block; 
}
.Female:hover :nth-child(3) { 
  display: none; 
}

.icon:hover{
  fill: #fff ;
}
.emphasis {
  font-style: italic;
}
p {
  padding: 0;
  margin: 0;
}
#graph{
  width: 100%;
  margin: auto;
}
h1{
  text-align: center;
  font-size: 2.5rem;
}
h3{
  margin: 0px;
}
.Male {
  /* height: 100% !important; */
  background: #FFF;
  border: 1px solid #737D67;
  border-radius: 3px;
  color: #737D67;
  box-sizing: border-box;
}
.Male:hover {
  background: #737D67 !important;
  border: 1px solid #737D67;
  color: #fff ;
}
.Female {
  /* height: 100% !important; */
  color: #C67D49;
  background: #FFF;
  border: 1px solid #C67D49;
  border-radius: 3px;  
  box-sizing: border-box;
}
.Female.dead{
  background: #FAF2ED;
  border: 1px solid #F5E6DD; 
}
.Male.dead{
  background: #F1F2F0;
  border: 1px solid #DEE1DB;
}
.Female:hover {
  background: #C67D49;
  border: 1px solid #C67D49;
  color: #fff;
}
div.Male .profileimage{
  border:1px solid #737D67;
  border-radius: 50%;
}
div.Female .profileimage{
  border:1px solid #C67D49;
  border-radius: 50%;
}
h1{
  font-family: 'myFirstFont' !important;
  font-size: 22px;
}
h3{
  font-family: 'myFirstFont' !important;
  font-size: 12px;
}
a{
  text-decoration: none;
  font-size: 22px;
}
 
.download-button{
  font-size: 32px;
  text-align: center;
}

/* Make the download icon look clickable when you hover over it. */
.download-button i {
  cursor: pointer;
}
.footer{
  height: 280px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: inherit;
}
.medium-font{
  color: #737D67;
  font-size: 1.5rem;
  text-align:right;
}
.large-font{
  color: #C67D49;
  font-size: 2rem;
}
.logo{
  width: 130px !important;
  height: 130px !important;
}
.footer-container{
  width: 75%; 
  height: 95%;
  margin: auto; 
  display: flex; 
  justify-content:space-between;
  
}
.QRcode{
  width: 80px;
  height: 80px;
}
.nav-burger{
  display: none;
}
#svgimage{
  height: 100vh;
  width: 100vw;
}
/* ===================================  responsive  ================================ */
@media screen and (max-width: 890px) {
  .nav-container{
    width: 95%;
    margin-left: 20px;
  }
  .medium-font{
    font-size: 1rem;
  }
  .large-font{
    font-size: 1.5rem;
  }
  .logo{
    width: 100px !important;
    height: 100px !important;
  }
  .footer-container{
    width: 95%;
  }
  .footer-container div a h1{
    font-size: 16px;
  }
  .globe{
    height: 25px !important;
  }

}
@media screen and (max-width: 570px) {
  .nav-container{
    width: 95%;
    margin-left: 20px;
  }
  .medium-font{
    font-size: 0.8rem;
  }
  .large-font{
    font-size: 1.2rem;
  }
  .logo{
    width: 80px !important;
    height: 80px !important;
  }
  .globe{
    height: 22px !important;
  }
}
@media screen and (max-width: 490px) {
  .nav-container{
    display: block;
  }
  .nav-left{
    display: flex;
    justify-content: center;
  }
  .nav-right{
    margin: 15px auto;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 450px) {
  .medium-font{
    font-size: 0.6rem;
  }
  .large-font{
    font-size: 1rem;
  }
  .logo{
    width: 40px !important;
    height: 40px !important;
  }
  .btn{
    font-size: 9px;
  }
  .footer-container{
    display: block;
    height: 0px !important;
  }
  .app-link-conatiner{
    display: block !important;
  }
  .QRcode-conatiner{
    margin-top: 35px;
  }
  .small-font{
   font-size: 9px !important; 
  }
  .footer{
    background-size: 100% !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
    height: 130px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .globe{
    height: 15px !important;
  }
  .QRcode{
    width: 40px;
    height: 40px;
  }
  /* .nav-left{
    display: none;
  } */
  /* .navbar{
    padding: 0px 0px;
  } */
  .nav-right{
    margin: 8px auto;
  }
  /* .btn-print-tree{
    padding: 3px 5px;
  } */
  /* .nav-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */
  .nav-burger{
    display: block;
  }
  /* #svgimage{
    height: 70vh;
    z-index: inherit;
  } */
}
